<script lang="ts">
export default {
    name: 'ResetPasswordInputs',
    props: {
        data: { type: Object, required: true },
        translationName: { type: String, required: true },
        hideSection : { type : Boolean, required : false}
    },
    emits: ['updateData'],
    data() {
        return {
            passwordConfirm: null,
            visible: false,

        }
    },
    computed: {
        rules() {
            return [
                () => {
                    if (!this.data?.k && this.data?.password)
                        return true

                    return 'Mot de passe obligatoire'
                },
                () => {
                    if ((this.data?.password === this.passwordConfirm) || (!this.data.password && !this.passwordConfirm))
                        return true

                    return 'Les mots de passe doivent être les mêmes.'
                },
                () => {
                    if (!this.data?.password || this.data?.password.length > 13)
                        return true

                    return 'Minimum 14 caractères'
                },

            ]
        },

    },
    methods: {
        forceValidate() {
            this.$refs.password.validate()
            this.$refs.confirm.validate()
        },
        isValidated () {
            this.forceValidate()
            let validation = []
            this.rules.forEach( rule => {
                if (rule() !== true) {
                    validation.push(rule())
                }
            })
            return !validation.length
        }
    },
}
</script>

<template>
    <FormSection v-if="($permissionCheck('user_canResetPassword') && data.k) || !data.k" title="Informations de sécurité" with-borders-color="danger" :hide-section>
        <p class="mb-2" v-if="($permissionCheck('user_canResetPassword') && data.k)">
            À remplir uniquement si vous souhaitez définir un nouveau mot de passe.
        </p>

        <div v-if="!data.guid">
            <label class="v-label">
                {{ $translate(`${translationName}.password`) }} 
                <span v-html="$config.public.form_label_required_text || '*'" />
            </label>
            <div class="text-caption text-medium-emphasis">
                Le mot de passe doit contenir au moins un chiffre, une lettre minuscule, une lettre majuscule, un caractère spécial et doit avoir une longueur minimale de 14 caractères.
            </div>
            <v-text-field
                ref="password"
                :model-value="data.password"
                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible ? 'text' : 'password'"
                variant="outlined"
                density="compact"
                :rules="rules"
                @click:append-inner="visible = !visible"
                @update:model-value="forceValidate(); $emit('updateData', { [`password`]: $event })"
            />
        </div>
        <div v-if="!data.guid">
            <label class="v-label">
                {{ $translate(`${translationName}.passwordConfirm`) }}
                <span v-html="$config.public.form_label_required_text || '*'" />
            </label>

            <v-text-field
                ref="confirm"
                v-model="passwordConfirm"
                :rules="rules"
                variant="outlined"
                density="compact"
                :type="visible ? 'text' : 'password'"

                @input="forceValidate"
            />
        </div>
    </FormSection>
</template>
